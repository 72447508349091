






























































































































































import { Component, Vue } from 'vue-property-decorator';

import {
  dispatchDeleteTgTask,
  dispatchGetTgTasks,
  dispatchKillTgTask,
  dispatchRunTgTask,
  dispatchStatTgTasks,
  dispatchDownloadTgTaskResult,
  dispatchDownloadTgTaskFile,
} from '@/store/tg-tasks/actions';
import { readTgTasks } from '@/store/tg-tasks/getters';
import { TG_TASK_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';
import { ITgTaskStats } from '@/interfaces/tg-tasks';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class TgTasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    {
      text: 'Глубина',
      sortable: true,
      value: 'deep',
      align: 'left',
    },
    {
      text: 'Создано',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Завершено',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readTgTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetTgTasks(this.$store);
    await dispatchStatTgTasks(this.$store);
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetTgTasks(this.$store);
      await dispatchStatTgTasks(this.$store);
      }, TG_TASK_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeleteTgTask(this.$store, {id: taskId});
    await dispatchGetTgTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunTgTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillTgTask(this.$store, {id: taskId});
  }

  public async downloadTgTaskResult(taskId: number, result: string) {
    await dispatchDownloadTgTaskResult(this.$store, { id: taskId, result });
  }

  public async downloadTgTaskFile(taskFile: string) {
    await dispatchDownloadTgTaskFile(this.$store, { taskFile });
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: ITgTaskStats, divider = 'found_count', divisible = 'total_count') {
    if (!statData[divisible]) return 0;
    return Math.floor(statData[divider] / statData[divisible] * 100);
  }

}
